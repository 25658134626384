<template>
    <modal class="shiftCompletionModel" :isShow="isShow"  @keyup.esc="hide">
        <div class="header">结班操作</div>
        <div class="modal-body flex-box">
            <div class="left-box">
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">营业日期:</div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                                :clearable="false"
                                :editable="false"
                                v-model="businessHours"
                                type="date"
                                placeholder=""
                                readonly
                                :default-value="new Date()">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="td-5">
                        <div class="lable-txt">结班人:</div>
                        <div class="from-input">
                            <input type="text" v-model="shiftEndName" readonly />
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">营业门店:</div>
                    <div class="from-input">
                        <input type="text" v-model="department_Name" readonly />
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt"></div>
                    <div class="from-input">
                        <label><input type="checkbox" v-model="isJoinTakeOut" @click="isJoinOrdersClick(1)" />合并外卖订单</label>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt"></div>
                    <div class="from-input">
                        <label><input type="checkbox" v-model="isJoinOnlineSelf" @click="isJoinOrdersClick(2)" />合并自助买单订单</label>
                    </div>
                </div>
                <div class="ResultDesc">
                    {{ResultDesc}}
                </div>
            </div>
            <div class="right-box">
                <div class="title">结班单预览</div>
                <div class="preview">
                    <div ref="empty" class="empty-box verticalCenter" v-show="printBodies" >
                        <div v-html="Print_Html" style="display: inline-block;"></div>
                    </div>
                    <div class="empty-boxNo verticalCenter" v-show="printBodies==''">
                        <div class="content-box" style="text-align: -webkit-center;padding-top:50%;">
                            <svg class="iconfont" aria-hidden="true">
                                <use xlink:href="#icon-kongdingdan"></use>
                            </svg>
                            <div>无待结班订单</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
            <button class="btn btn-confirm" @click="confirm()">结班</button>
        </div>
    </modal>
</template>

<script>
import { newGuid,printView } from '/src/common/index.js'
// 结班操作
export default {
    name:'shiftCompletionModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**营业日期 */
            businessHours:new Date(),
            /**获取用户*/
            userInfo:'',
            /**结班人 */
            shiftEndName:'',
            /**营业门店 */
            department_Name:'',
            isJoinTakeOut:false,
            isJoinOnlineSelf:false,
            /**结账单信息 */
            printBodies:'',
            ResultDesc:'',
        }
    },
    computed:{
        /** 打印数据展示 html **/
        Print_Html(){
            console.log(this.printBodies)
            return printView(this.printBodies);
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            setTimeout(() => {
                this.$refs.empty.scrollTop=0;
            },400);
            this.printBodies=''
            this.userInfo= this.$auth.getUserInfo();
            if(this.userInfo){//营业日期
                this.businessHours=this.userInfo.Rpt_Date;
            }
            this.shiftEndName = this.userInfo.Login_Name
            this.department_Name = this.userInfo.Department_Name
            this.shiftEnd(1,false,false)
        },
        /**勾选条件 */
        isJoinOrdersClick(type){
            if(type==1){
                this.isJoinTakeOut = !this.isJoinTakeOut
            }else{
                this.isJoinOnlineSelf =!this.isJoinOnlineSelf
            }
            this.shiftEnd(1,this.isJoinTakeOut,this.isJoinOnlineSelf)
        },
        hide(){
            this.$emit("closeModel");
        },
        
        confirm(){
            this.shiftEnd(2,this.isJoinTakeOut,this.isJoinOnlineSelf)
            //this.$emit("confirm");
        },
        /**获取结班数据 */
        shiftEnd(type,IsJoinTakeOut,IsJoinOnlineSelf){
            this.printBodies=''
            const loading = this.$loading({
                text: "获取结班信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                ShiftOperation:type, //1.结班预览,2.确认结班
                IsJoinTakeOutOrders:IsJoinTakeOut, //是否加入未结班的外卖订单数据
                IsJoinOnlineSelfOrders:IsJoinOnlineSelf, //是否加线上自助扫码订单数据
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_Pos:this.userInfo?.Site_Name //操作站点
            }
            this.$httpAES.post("Bestech.CloudPos.ShiftEnd",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    if(type==2){
                        this.ResultDesc=''
                        this.$message.success("结班成功");
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(data.ResponseBody);
                        },100);
                        this.$auth.logout();
                    }else{
                        this.ResultDesc=data.ResponseHeader.ResultDesc
                        if(data.ResponseBody){
                            this.printBodies = data.ResponseBody[0].PrintBodies
                            this.userInfo= this.$auth.getUserInfo();
                        }
                    }
                }else if(data.ResponseHeader.ResultCode==8001){
                    this.$message.warning(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.error("结班信息获取失败:"+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.memberInfo=null,
                this.$message.error('结班信息读取失败:'+e.message);
                console.log('结班信息读取失败:',e);
            })
        },
    }
}
</script>

<style lang="scss">
@import './shiftCompletionModel.scss'
</style>